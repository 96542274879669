<template>
    <div>

        <section style="margin-top: 100px;" id="privacy_policy" class="container mx-auto px-12 pt-4 pb-12 text-gray-800 mt-12">
  <div class="lg:text-center"><h3 class="underline text-gray-700 text-4xl">SALESFORECAST TERMS OF SERVICE
</h3></div>

<p>PLEASE READ CAREFULLY: IF YOU ARE ACCEPTING THIS SOFTWARE-AS-A-SERVICE TERMS OF SERVICE (“AGREEMENT”) ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY (“CUSTOMER”), YOU REPRESENT AND WARRANT THAT YOU HAVE FULL AUTHORITY TO BIND THE CUSTOMER TO THIS AGREEMENT. UNLESS THE CUSTOMER HAS ANOTHER VALID AGREEMENT FOR THE PURCHASE AND USE OF SALESFORECAST PRODUCTS (AS DEFINED BELOW), THIS AGREEMENT GOVERNS YOUR RIGHTS TO THE SALESFORECAST    SERVICES AND PRODUCTS. BY USING THE WEBSITE OR PRODUCT CUSTOMER ACCEPTS THAT THIS AGREEMENT WILL BE DEEMED A BINDING CONTRACT BETWEEN SALESFORECAST AND CUSTOMER. IF CUSTOMER DOES NOT AGREE TO OR CANNOT COMPLY WITH ALL OF THE TERMS OF SERVICE SET FORTH IN THIS AGREEMENT OR IF YOU DO NOT HAVE AUTHORITY TO BIND THE CUSTOMER, THEN DO NOT CONTINUE BROWSING THE WEBSITE OR AND CUSTOMER WILL NOT BE AUTHORIZED TO USE THE PRODUCTS/SERVICES.
</p>

  <ul class="list-disc mt-8">
  <li class="text-2xl uppercase"><b>CONDITIONS TO ACCEPT THE TERMS</b></li>
    <p>If you create an account, you are declaring that you are at least 18 years old. If you are not of legal age to enter into a contract, then you must review these Terms with your parent or guardian, who must accept them, to access the Services. If you are a User, the Services are intended to be used by people aged 16 years or older. If you are not aged 16 or older please quit the Services.
</p>
  <li class="text-2xl uppercase mt-8"><b>SERVICES</b></li>
  <p>Access and Use. Subject to the Terms of Service of this Agreement, during the applicable License Term, Salesforecast hereby grants to Customer (Is) a non-exclusive, non-transferable and non-sublicensable license access to the Software, in each case, solely for Customer’s internal use with Customer’s ordinary business operations and in accordance with the applicable Documentation. Customer may provide access to the SaaS Product to its and its Affiliates’ employees, contractors, and other individual users to access and use the SaaS Products on its behalf and for its internal business purposes in compliance with this Agreement, provided Customer is responsible for all such users’ actions that violate the terms of this Agreement, any breach by any such user is a breach by Customer, and no such user is an employee or contractor of a Salesforecast Competitor (defined below). Customer agrees that its purchase of the Software is neither contingent upon the delivery of any future functionality or features nor dependent upon any oral or written public comments made by Salesforecast with respect to future functionality or features.</p>

  <li class="text-2xl uppercase mt-8"><b>RIGHTS OF ACCESS AND USE</b></li>
  <p>Salesforecast (“Salesforecast’s Software”) websites and domains, including Salesforecast.io, and all of the webpages, subdomains, country-level domain variants and subparts of those websites (collectively, our “Site”), all of the services available on or through the Site or otherwise provided by us (including our application programming interfaces), and all of our free mobile applications (collectively, the “Applications”) are offered, maintained and provided by Salesforecast. We refer to all of these as our “Services.”</p>








  <li class="text-2xl uppercase mt-8"><b>PAYMENT ADDITIONAL LICENSES; REPORTING. </b></li>

    <p>The customer shall pay the fees for Salesforecast products and/or services as set forth in the applicable Order. If the Customer is purchasing through a reseller, payment terms will be determined by the Customer and the reseller. If the Customer is purchasing directly from Salesforecast, all fees shall be paid in U.S. dollars and are due within thirty (30) days of the invoice. Customer shall pay all applicable fees, insurance costs, and taxes, excluding taxes on Salesforecast’s net income. If the actual number of registered devices or users (as applicable) exceed the number of licenses purchased by Customer in the applicable Order, then Customer shall (a) immediately cease such excess usage or (b) purchase additional licenses to cover the excess usage. Fees for excess usage shall be based on Salesforecast’s then-current price list or specified in the Order. The customer acknowledges that Salesforecast’s delivery and support infrastructure may enable Salesforecast to access the device or user count for the SaaS Product. Upon written notice by Salesforecast, the Customer shall certify in writing the number and type of registered devices or users.
 </p>

 <li class="text-2xl uppercase mt-8"><b>CONFIDENTIALITY.</b></li>


    <ul class="list-disc mt-4 ml-12">
      <li class="text-xl "><u><b>Provide Services to you:</b></u> We collect information to send you email about your registration, perform tasks required to complete a transaction, provide customer support, or provide other types of customer relationship management and fulfillment. We may also use your information to optimize or improve our Services and operations, for example, training and quality assurance.
 </li>
      <li class="text-xl mt-4"><u><b><i>Definition.</i></b></u> “Confidential Information” means non-public information provided by one party (“Discloser”) to the other (“Recipient”) that is designated as confidential or reasonably should be considered as such, excluding information that (i) is or becomes public through no fault of the Recipient, (ii) was known to Recipient before the disclosure, (iii) is disclosed to Recipient by a third party without violation of any confidentiality restrictions, or (iv) is independently developed by the Recipient without access to or use of the Discloser’s information. Salesforecast     Confidential Information includes but is not limited to all SaaS Products (and any derivatives, performance data, benchmark results, security assessments, roadmaps, and any other technical information relating to the SaaS Products), Documentation and its derivatives, and Salesforecast’s pricing. The Terms of Service of this Agreement are the Confidential Information of both parties.
 </li>

   <li class="text-xl mt-4"><u><b><i>Non-disclosure and Non-Use.</i></b></u> The Recipient shall (i) only use the Confidential Information of the Discloser to exercise its rights and/or to perform under this Agreement, (ii) use the same degree of care to prevent unauthorized use and disclosure of Discloser’s Confidential Information as it does for its confidential information, but in no event less than reasonable care, and (iii) with respect to employees, contractors, or agents of Recipient, limit access to the Discloser’s Confidential Information only to those employees, contractors, or agents who have a need to access such Confidential Information and who are subject to confidentiality obligations at least as restrictive as those specified in this Section 6. The Recipient may disclose the Discloser’s Confidential Information to the extent required by any court, governmental body, or law or regulation, provided that, if legally permissible, Recipient shall provide prompt written notice to the Discloser of such disclosure. Upon written request of the Discloser, the Recipient shall return or destroy, at Discloser’s option, the Discloser’s Confidential Information.
 </li>


      
    </ul>


    <li class="text-2xl uppercase mt-8"><b>SECURITY</b></li>


      <ul class="list-disc mt-4 ml-12">
      <li class="text-xl">To ensure that this website remains available to all users, we may monitor network traffic to identify unauthorized attempts to upload or change information, or to otherwise cause damage to the site. Anyone using this site expressly consents to such monitoring.</li>

      <li class="text-xl mt-4">Unauthorized attempts to modify, alter, deface, destroy or corrupt any information stored on this site or this system, to defeat or circumvent any security features, to probe, scan or test for vulnerabilities, to breach security or authentication measures, to forge Salesforecast headers, to install or attempt to install unauthorized software, to mount denial-of-service attacks, or to utilize this system for other than its intended purposes are expressly prohibited and may result in criminal prosecution.</li>
      <li class="text-xl mt-4">Any possible criminal activity will be reported, together with any evidence that may be gathered, to the appropriate authorities.</li>
      <li class="text-xl mt-4">If you choose or are provided with a user identification code, password, or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party. We have the right to disable any user identification code or password whether chosen by you or allocated by us at any time if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</li>

    </ul>


      <li class="text-2xl uppercase mt-8"><b>INTELLECTUAL PROPERTY</b></li>

      <p>Salesforecast owns the Services and the Sites, including all the source code, design features, functionality, rights, and intellectual property. The trademarks, logos, service marks, photos, videos, and content (“Platform Content”) are either owned by us or licensed to us and they are protected by copyright and trademark laws and various other intellectual property rights. The Platform Content provided within the Services and the Sites are for the use of the Customer and the Users only, and may not be used for any commercial use, except through the use of the Services or otherwise expressly authorized by us.
</p>
    <p>Salesforecast may continue to develop the Services, add features, and/or make them available on different platforms. We may change the concept and features of the Services at our discretion. We may separate the Services into different smaller products or make the Services a part of another product. The Customer understands and agrees that we are under no obligation to keep the Services as they are at the time of the signing of the contract and the Services are subject to change and evolve according to our development plans and Customer feedback.
</p>

<p>Salesforecast grants the Customer a nonexclusive, nontransferable, limited license, which may not be sublicensed, for the use of the Services by the Customer, Users, and Guests in accordance with the Contract and Terms of Service.</p>

  

      <li class="text-2xl uppercase mt-8"><b>OWNERSHIP</b></li>

      <p>Salesforecast owns and retains all right, title, and (except as expressly licensed in this Agreement) interest in and to the SaaS Product and its derivative works. The customer is not obligated to provide Salesforecast with any suggestions or feedback about the products or services (“Feedback”). To the extent Customer does provide Feedback to Salesforecast, Customer assigns ownership of such Feedback to Salesforecast and Salesforecast may use and modify such Feedback without any restriction or payment.</p>


      <li class="text-2xl uppercase mt-8"><b>CUSTOMER DATA</b></li>

      <p>During the use of the Services, Users submit different kinds of information and data to the Workspace, such as images, videos, comments, experiences, articles, reports, descriptive text, live recordings, hyperlinks, data acquired from the web, messages, data processed through the service, personal information, and information generated by the User (“Customer Data”).</p>

      <p>Customer is the sole authority to decide what to do with Customer Data. Customers can contact us to provide instructions on what to do with it. Customers may export the content, manage Users and User roles, create integrations, manage permissions, create information types, change settings, adjust privacy, delete all or some of the Content, or share Customer Data.</p>
<p>Customer Data is supplied by the Customer and the sole responsibility for the Customer Data belongs to the Customer. We do not check any part of the Customer Data and are not responsible in any way for its contents, including but not limited to whether it infringes on anyone else’s copyrights, violates any laws, or contains offensive or otherwise sensitive information. The way Customer Data is collected, generated, stored, and processed is decided and carried out by the Customer, and we do not have any say in it or responsibility for it.</p>
<p>All rights, including intellectual property rights in Customer Data, belong exclusively to the Customer. Whenever a User or Guest submits content, it automatically becomes part of Customer Data and the property of the Customer. The customer has full rights to give or limit access; change privacy settings; and share, delete, or move any Customer Data that you or any User has generated and submitted, regardless of the type of content submitted.</p>
<p>Customer hereby gives us a royalty-free, worldwide, nonexclusive license to store, process, use, access, modify, display, transfer, back up, analyze, and copy Customer Data to provide the Services, to enhance the user experience for the Customer and Users, and to share parts of Customer Data upon the request of the Customer.</p>
<p>As the choices of the Customer may have a huge impact on how Customer Data is processed or disclosed, it is very important to bear in mind the effects of these actions.
</p>
     

<li class="text-2xl uppercase mt-8"><b>RESTRICTIONS</b></li>
<p>Except as otherwise stated, you and any User may not do, and you shall not allow other Users to do, the following:
</p>

  <ul class="list-disc mt-4 ml-12">
      <li class="text-xl">To ensure that this website remains available to all users, we may monitor network traffic to identify unauthorized attempts to upload or change information, or to otherwise cause damage to the site. Anyone using this site expressly consents to such monitoring.</li>

      <li class="text-xl mt-4">sell, rent, lease, sublicense, gift, transfer, transmit, publish, or make available any portion of the Services to third parties, including but not limited to your affiliates;</li>
      <li class="text-xl mt-4">disable, bypass, or interfere with any part of the Services in any way to override restrictions, enable features, or change the way the Services are intended to work;</li>
      <li class="text-xl mt-4">systematically retrieve data or any kind of content from the Site or the Services to create or compile, directly or indirectly, a product, database, software, or directory without our prior permission;</li>

      <li class="text-xl mt-4">collect usernames or emails of Users for marketing purposes such as sending unsolicited emails;</li>
      <li class="text-xl mt-4"> deceive, divert, or mislead us or Users of the Services to obtain sensitive account information or access Customer Data;</li>
      <li class="text-xl mt-4"> intentionally cntact support with improper requests;</li>
      <li class="text-xl mt-4">• intentionally submit false reports of misuse or misconduct;</li>
      <li class="text-xl mt-4"> attempt to impersonate any User without permission for improper actions;</li>
      <li class="text-xl mt-4"> copy any part of the content that comes with the Services to use elsewhere, other than for promoting the Services, without prior permission from us;</li>
      <li class="text-xl mt-4"> try to access, derive, or extract any part of the source code of the Services and its components by reverse engineering, decompiling, decrypting, hacking, using special apps, or any other means;</li>
      <li class="text-xl mt-4"> use any kind of automated software or hardware to access or monitor the Services for any reason;</li>
      <li class="text-xl mt-4"> knowingly use methods or technologies that would impose unreasonable loads on the infrastructure that serves the Services;</li>
      <li class="text-xl mt-4"> modify, translate or change any part of the Services without prior permission from us;</li>
      <li class="text-xl mt-4"> copy or create any derivatives of the Services or Sites or any part thereof;</li>
      <li class="text-xl mt-4"> develop or use any technology to impede the Services;</li>
      <li class="text-xl mt-4"> interfere with the integrity of the Services;</li>
      <li class="text-xl mt-4"> override or attempt to override the authentication process of the Services;</li>
      <li class="text-xl mt-4"> remove, modify, or conceal logos, trademarks, attributions or other proprietary rights affixed to or provided within the Services or Sites or any third party we work with without our prior written approval;</li>
      <li class="text-xl mt-4"> use or display logos, marks and other attributions related to us or the Services other than promoting the Services without our prior written approval;</li>
      <li class="text-xl mt-4"> use, copy, or imitate parts of the Services, or content within the Services or Sites, to develop a competing service or product;</li>
      <li class="text-xl mt-4"> use the Services for any illegal purposes or in a way that would be unlawful;</li>
      <li class="text-xl mt-4"> use the Services in a way that would be harmful to Users, Customers, or any third parties;</li>
      <li class="text-xl mt-4"> use the Services for the purpose of unauthorized marketing activities;</li>
      <li class="text-xl mt-4"> post disparaging, harmful, offensive, or fraudulent content that is open to the public that might harm us or other Users of the Services;</li>
      <li class="text-xl mt-4"> try to hack, access, or obtain login credentials, accounts, or the content of Customers, individuals or organizations;</li>
      <li class="text-xl mt-4"> attempt to upload or transmit any harmful code such as viruses or Trojans to the Services or Sites that might interfere with, disrupt, alter, or modify the performance or integrity of the Services;</li>
      <li class="text-xl mt-4"> Attempt to upload or transmit any material that acts as a passive or active information collector or transmitters, such as web bugs, cookies, or 1x1 gifs; or use the Services in any way that would be a breach of the Contract.</li>


    </ul>
    <p>The customer agrees to be liable to us for any act listed above carried out by any User that would be a breach of the Terms of Service as if such acts or breaches were done by the Customer.
</p>

<li class="text-2xl uppercase mt-8"><b>LINKS</b></li>
<p>There may be links provided within the Services or the Sites that do not belong to and are not controlled by us. You understand and agree that we have no liability or responsibility for their content or reliability, the way you use them, or the privacy policies of any third-party websites. It is solely your responsibility to choose to click on these links and make use of the content in any way you would like.</p>
<p>
You may create and use links about the Services and Sites under the following conditions:
</p>
  <ul class="list-disc mt-4 ml-12">
      <li class="text-xl">
You shall not replicate the Services or Sites in part or whole.
      </li>
      <li class="text-xl">
You shall not misrepresent the content of the link provided.
      </li>
      <li class="text-xl">
You shall not use offensive or disparaging comments with your links.

      </li>
      <li class="text-xl">
You shall not infringe on any intellectual property rights or rights of any third party.

      </li>
      <li class="text-xl">
Your link shall not be published in an environment that is considered unlawful, unethical, or offensive
      </li>
    </ul>
    <li class="text-2xl uppercase mt-8"><b>INDEMNITY</b></li>

    <ul class="list-disc mt-4 ml-12">
      <li class="text-xl">
<b><i>Indemnification by Salesforecast.</i></b> Salesforecast shall at its cost and expense (i) defend or settle any claim brought against Customer and its directors, officers and employees (“Customer Indemnitee(s)”) by an unaffiliated third party alleging that Customer’s use of the SaaS Product infringes or violates that third party’s intellectual property right(s), and (ii) pay, indemnify and hold Customer Indemnitees harmless from any settlement of such claim or any damages finally awarded to such third party by a court of competent jurisdiction as a result of such claim.

      </li>
      <li class="text-xl">
<b><i>Remedies.</i></b> If a claim occurs or in Salesforecast’s opinion is reasonably likely to occur, Salesforecast may at its expense and sole discretion: (i) procure the right to allow Customer to continue using the applicable SaaS Product, (ii) modify or replace the applicable SaaS Product to become non-infringing, or (iii) if neither (i) nor (ii) is commercially practicable, terminate Customer’s license to the affected portion of applicable SaaS Product and refund a portion of the pre-paid, unused license fees paid by Customer corresponding to such SaaS Product.
      </li>
      <li class="text-xl">
<b><i>Exclusions.</i></b> Salesforecast shall have no obligations under this Section if the claim is based upon or arises out of (i) any modification to the applicable SaaS Product not made by or at the direction of Salesforecast, (ii) any combination or use of the applicable SaaS Product with any third party equipment, products or systems, to the extent that such claim is based on such combination or use, (iii) Customer’s continued use of the allegedly infringing technology after being notified of the infringement claim, (iv) Customer’s failure to use Updates made available by Salesforecast, (v) Customer’s failure to use the SaaS Product in accordance with the applicable Documentation, and/or (vi) use of the SaaS Product outside the scope of the license granted under this Agreement. This Section 7 constitutes Customer’s sole and exclusive remedies, and Salesforecast’s entire liability, with respect to infringement of third-party intellectual property rights.You shall not use offensive or disparaging comments with your links.

      </li>
      <li class="text-xl">
<b><i>Indemnification by Customer.</i></b> Customer shall at its cost and expense (i) defend or settle any claim brought against Salesforecast and its directors, officers and employees (“Salesforecast     Indemnitee(s)”) by an unaffiliated third party alleging that the Customer Data infringes or violates a third party’s intellectual property or privacy right(s), and (ii) pay, indemnify and hold Salesforecast Indemnitees harmless from any settlement of such claim or any damages finally awarded to such third party by a court of competent jurisdiction as a result of such claim. “Customer Data” means any data originated by Customer or Customer Representatives that Customer or Customer Representatives submit to the SaaS Product.
      </li>
      <li class="text-xl">
<b><i>Procedures.</i></b> Each indemnitor’s indemnification obligation is conditioned on the indemnitee: (i) giving the Indemnitor prompt written notice of such claim, (ii) permitting the Indemnitor to solely control and direct the defense or settlement of such claim, provided the Indemnitor shall not settle any claim in a manner that requires the indemnitee to admit liability or pay money without the indemnitee’s prior written consent, and (iii) providing the Indemnitor all reasonable assistance in connection with the defense or settlement of such claim, at the indemnitor’s cost and expense.
      </li>
    </ul>

    <li class="text-2xl uppercase mt-8"><b>SUPPORT AND MAINTENANCE SERVICES, HARDWARE, AND RESALE PRODUCTS.</b></li>

    <ul class="list-disc mt-4 ml-12">
      <li class="text-xl">
        <b><i>Support and Maintenance Services.</i></b> Support and maintenance services shall be provided in accordance with the support and maintenance Terms of Service.
      </li>
      <li class="text-xl"><i><b>Third-Party Services.</b></i> The Software may contain features designed to interface with applications or services provided or made available by third parties (“Third Party Services”). In order to use a feature in connection with a Third-Party Service, the Customer must have a license from the provider of the relevant Third-Party Service. If the Third-Party Services are no longer available or if the applicable third-party provider no longer allows the Third-Party Services to interface with the Software, then such features will no longer be available or function in the Software. Salesforecast and the provider of the applicable Third Party Service disclaim all warranties, indemnities, obligations, and other liabilities in connection with any interface or integration with the Third Party Service. Further, Salesforecast disclaims all warranties, indemnities, obligations, and other liabilities in connection with any Third Party Service.
    </li>
  </ul>


  <li class="text-2xl uppercase mt-8"><b>WARRANTIES</b></li>

    <ul class="list-disc mt-4 ml-12">
      
      <li class="text-xl"><i><b>SaaS Product.</b></i> Salesforecast represents and warrants to Customer that the SaaS Product materially conforms to the specifications specified in the relevant Documentation. The customer must notify Salesforecast of any warranty deficiencies within thirty (30) days from the provision of the deficient SaaS Product. Customer’s sole and exclusive remedy and the entire liability of Salesforecast for Salesforecast’s breach of this warranty will be for Salesforecast, at its option, to (i) repair such SaaS Product (and/or deliver new applicable Software) or (ii) terminate the applicable License Term and refund any prepaid, unused subscription fees paid to Salesforecast for the unused period of any such terminated License Term.
    </li>
    <li class="text-xl"><i><b>Professional Services.</b></i> Customers may order Salesforecast professional services from any authorized reseller or directly from Salesforecast. Such professional services shall be subject to the Terms of Service of this Agreement and mutually agreed-upon statement of work (if any). For ninety (90) days following the date of delivery of any professional service by Salesforecast to Customer, Salesforecast represents and warrants that such professional services shall be professional, workman-like, and performed in a manner conforming to generally accepted industry standards and practices for similar services. Customer’s sole and exclusive remedy and the entire liability of Salesforecast for Salesforecast’s breach of this warranty will be for Salesforecast, at its option, to re-perform the non-conforming services or refund the fees paid for such non-conforming professional services.
    </li>
    <li class="text-xl"><i><b>Exclusions.</b></i> The express warranties do not apply if the applicable SaaS Product or hardware (i) has been modified, except by or at the direction of Salesforecast, (ii) has not been installed, used, or maintained in accordance with this Agreement and Documentation, (iii) has been subjected to abnormal physical or electrical stress, misuse, negligence or accident, and/or (iv) is used with equipment, products or systems not specified in the Documentation. Additionally, these warranties only apply if notice of a warranty claim is provided within the applicable warranty period.
    </li>
    <li class="text-xl"><i><b>Disclaimer.</b></i> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT FOR THE WARRANTIES EXPRESSLY STATED IN THIS SECTION, THE SAAS PRODUCT, HARDWARE, AND SERVICES ARE PROVIDED “AS IS,” AND SALESFORECAST PROVIDES NO OTHER REPRESENTATIONS AND WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND SALESFORECAST     SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
    </li>




  </ul>

  <li class="text-2xl uppercase mt-8"><b>TERM AND TERMINATION. </b></li>

  <p>The access rights granted herein with respect to the SaaS Product shall remain effective until the License Term for the relevant SaaS Product expires or the license for the relevant SaaS Product is terminated. This Agreement shall remain effective until the earliest of termination in accordance with this Section 10, or expiration of the applicable License Term. If Salesforecast agrees to reinstate a lapsed subscription license, then the terms of this Agreement shall apply. Either party may terminate this Agreement: (a) upon thirty (30) days’ written notice of a material breach by the other party (or three (3) business days in the case of a failure to pay) unless the breach is cured within the notice period, or (b) immediately, if the other party ceases to do business, becomes insolvent, or seeks protection under any bankruptcy or comparable proceedings. In addition, the parties may terminate this Agreement by mutual written consent. All other licenses terminate upon expiration or termination of this Agreement. In addition, all liabilities that accrue prior to termination shall survive expiration or termination of this Agreement for any reason. For ninety (90) days after the expiration or termination of this Agreement, upon Customer’s request, Salesforecast shall permit Customer to access the SaaS Product solely to the extent necessary for Customer to retrieve applications uploaded to such SaaS Product by Customer. After such 90 days, Salesforecast may delete all Customer Data in Salesforecast’s possession or control.
</p>

  <li class="text-2xl uppercase mt-8"><b>LIMITATION OF LIABILITIES</b></li>
  <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT FOR ANY BREACHES OF OR FOR LIABILITY ARISING OUT OF SECTION 11 (RESTRICTIONS), 5 (CONFIDENTIALITY) OR A PARTY’S INDEMNIFICATION OBLIGATIONS UNDER THIS AGREEMENT, OR CUSTOMER’S PAYMENT OBLIGATIONS, NEITHER PARTY SHALL BE LIABLE TO THE OTHER PARTY IN CONNECTION WITH THIS AGREEMENT OR THE SUBJECT MATTER HEREOF (UNDER ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STATUTE, TORT OR OTHERWISE) FOR (a) ANY LOST PROFITS, LOST BUSINESS OPPORTUNITIES, LOST DATA, OR SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES OR SUCH DAMAGES OR LOSSES WERE REASONABLY FORESEEABLE, OR (b) AN AMOUNT THAT EXCEEDS THE TOTAL FEES PAYABLE TO SALESFORECAST     FOR THE RELEVANT SAAS PRODUCT, HARDWARE, OR SERVICE DURING THE TWELVE MONTHS BEFORE THE EVENT GIVING RISE TO SUCH LIABILITY. THESE LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY SPECIFIED IN THIS AGREEMENT. MULTIPLE CLAIMS SHALL NOT EXPAND THE LIMITATIONS SPECIFIED IN THIS SECTION 17.

  </p>

    <li class="text-2xl uppercase mt-8"><b>GENERAL</b></li>

    <ul class="list-disc mt-4 ml-12">
      <li class="text-xl">
<b><i>Export/Import.</i></b> The SaaS Product and Documentation may be subject to U.S. and foreign import and export control laws and regulations. The customer agrees to comply with all such regulations applicable to Customer, including obtaining applicable import licenses.

      </li>
      <li class="text-xl">
<b><i>U.S. Government End Users.</i></b> The SaaS Product and Documentation are “commercial items,” as that term is defined in 48 C.F.R. 2.101, consisting of “commercial computer software” and “commercial computer software documentation,” as such terms are used in 48 C.F.R. 12.212. Consistent with 48 C.F.R. 12.212 and 48 C.F.R.227.7202-1 through 227.729204, the SaaS Product and Documentation are being licensed to U.S. Government end users only as “commercial items” and with only those rights as are granted to all other end users pursuant to the Terms of Service of this Agreement.
      </li>
      <li class="text-xl">
<b><i>Governing Law and Jurisdiction.</i></b> Except as otherwise provided, this Agreement, and the rights and duties of the parties arising from this Agreement, shall be governed by, construed, and enforced in accordance with the Federal laws of the United State of America and the state laws of Delaware excluding its conflicts-of-law principles

      </li>
      <li class="text-xl">
<b><i>Assignment.</i></b> Neither party may assign this Agreement without the prior written consent of the other party, provided however either party may do so to a successor-in-interest pursuant to a merger, acquisition, or sale of all or substantially all of its business and/or assets. Any assignment in violation of this Section 19d shall be void. Subject to the foregoing, all rights and obligations of the parties under this Agreement shall be binding upon and inure to the benefit of and be enforceable by and against the successors and permitted assigns.
      </li>
      <li class="text-xl">
<b><i>Data Communications.</i></b> Salesforecast shall only collect, access, use, store, safeguard, disclose and transfer (“Process”) Personal Information (i) for this Agreement, including without limitation, to implement and deliver the SaaS Product and its features and associated services, provide Customer support, and help Customer prevent or address service or technical problems, (ii) as otherwise expressly permitted by Customer in writing, or (iii) as compelled by law. Customers shall make such disclosures, obtain such consents, and provide such access, choices, and other applicable rights to individual users with regard to the Processing of Personal Information as are required under applicable law, rules, or regulations. “Personal Information” means any information relating to an identified or identifiable individual user that is obtained by or communicated to Salesforecast by Customer in performance by Salesforecast of its obligations under this Agreement. Salesforecast collects, analyzes, and uses aggregated, de-identified technical data and related information (such as product or feature usage, device metrics/metadata, and/or mobile application usage) to facilitate market research, product development/improvement and to provide support and maintenance services. Salesforecast may use, store, or disclose such information or material derived from such information, as long as it is in a form that does not identify or is not attributable to any individual.

      </li>
      <li class="text-xl"><b><i>Equitable Relief.</i></b> The parties agree that a material breach of this Agreement adversely affecting Salesforecast’s or its suppliers’ intellectual property rights in the SaaS Product or either party’s Confidential Information may cause irreparable injury to such party for which monetary damages would be an inadequate remedy and the non-breaching party shall be entitled to equitable relief (without a requirement to post a bond) in addition to any remedies it may have under this Agreement or at law.</li>

      <li class="text-xl"><b><i>Publicity.</i></b> Salesforecast may publicly disclose that Customer is a customer of Salesforecast and a licensee of the SaaS Product, including in a list of Salesforecast customers and other promotional materials.</li>
      <li class="text-xl"><b><i>Independent Contractor.</i></b> The parties are independent contractors. This Agreement shall not establish any relationship of partnership, joint venture, employment, franchise, or agency between the parties.</li>
      <li class="text-xl"><b><i>Customer Affiliates.</i></b> An Affiliate may purchase Salesforecast products and/or services either by executing a participation agreement with Salesforecast or through Salesforecast’s acceptance of an Order issued by such Affiliate or Authorized Reseller (as applicable). Upon execution of a participation agreement by Affiliate or, if the Affiliate issues a purchase order referring to this Section 12.i, that is accepted by Salesforecast, such Affiliate shall be deemed to have purchased such products and/or services hereunder, and such Affiliate shall be bound by and shall comply with the Terms of Service of this Agreement as a “Customer” under the Agreement.</li>

      <li class="text-xl"><b><i>Waiver & Severability;</i></b> Amendments. The failure of either party to enforce any provision of this Agreement shall not constitute a waiver of any other provision or any subsequent breach. If any provision of this Agreement is held to be illegal, invalid or unenforceable, the provision will be enforced to the maximum extent permissible to affect the intent of the parties, and the remaining provisions of this Agreement will remain in full force and effect. This Agreement may only be amended, or any term or condition set forth herein waived, by written consent of both parties.</li>
      <li class="text-xl"><b><i>Notices.</i></b> Except as otherwise provided in this Agreement, all legal notices to Customer will be given in writing to any Customer address listed on the applicable Order. All legal notices to Salesforecast will be given in writing to: <br><br>
        <span class="ml-6">SALESFORECAST LLC</span><br>
        <span class="ml-6">519 SW Park Ave #303,</span><br>
        <span class="ml-6">Portland, OR 97205</span><br>
        <span class="ml-6">Email: <a class="text-blue-500" href="mailto:support@salesforecast.io">support@salesforecast.io</a> </span><br>
        Such notices will be effective (a) when personally delivered, (b) on the reported delivery date if sent by a recognized international or overnight courier or by fax, or (c) five business days after being sent by registered or certified mail (or ten days for international mail). For clarity, purchase orders, invoices, and other documents relating to order processing and payment are not legal notices and may be delivered electronically in accordance with Salesforecast and Customer’s standard ordering procedures.
      </li>

          <li class="text-xl"><b><i>Entire Agreement.</i></b> This Agreement consists of these Terms of Service, and the attached schedule(s), which are incorporated by reference. This Agreement constitutes the entire agreement between the parties with respect to its subject matter and supersedes and cancels all prior agreements, representations, communications, and understandings of the parties, written or oral, relating to such subject matter, and is not intended to confer upon any person other than the signatories below any rights or remedies. This Agreement prevails over any conflicting, or additional terms of any ordering document, acknowledgment, confirmation, or other document issued by Customer before or after the execution of this Agreement unless such conflicting or additional terms have been introduced via an amendment and accepted in writing by both parties. The headings of sections of this Agreement are for convenience and are not for use in interpreting this Agreement.
</li>


        <li class="text-xl"><b><i>Modification and Discontinuation.</i></b> We reserve the right at any time and from time to time to modify, edit, delete, suspend or discontinue, temporarily or permanently this Web site (or any portion thereof) and/or the information, materials, products and/or services available through this Web site (or any part thereof) with or without notice. You agree that we shall not be liable to you or any third party for any such modification, editing, deletion, suspension or discontinuance of this Web site.<br>
You agree that Salesforecast may, in its sole discretion, at any time terminate your access to the Site and any account(s) you may have in connection with the Site. Salesforecast may monitor access to the Site.</li>
    </ul>



</ul>


</section>

    </div>
</template>
<script>
export default {
    
}
</script>