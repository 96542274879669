<template>
    <div>

        <section style="margin-top: 100px;" id="privacy_policy" class="container mx-auto px-12 pt-4 pb-12 text-gray-800">
  <div class="lg:text-center"><h3 class="underline text-gray-700 text-4xl">SALESFORECAST PRIVACY POLICY</h3></div>

  <ul class="list-disc mt-8">
  <li class="text-2xl uppercase"><b>OVERVIEW OF THIS POLICY</b></li>
    <p>This Privacy Policy (this “Policy”) relates to the personal information that you submit when you access and use our website and other information we automatically collect when you access the website. This Policy explains all activities regarding your information, including but not limited to how we collect, use, disclose, and secure it and the rights available to you.</p>
    <p>This Privacy Policy (this “Policy”) relates to the personal information that you submit when you access and use our website and other information we automatically collect when you access the website. This Policy explains all activities regarding your information, including but not limited to how we collect, use, disclose, and secure it and the rights available to you.</p>
  <li class="text-2xl uppercase mt-8"><b>WHAT INFORMATION DOES SALESFORECAST COLLECT FROM YOU?</b></li>
  <p><b>2.1</b> We collect information from and about users of our Website:</p>
    <ul class="list-disc mt-4 ml-12">
      <li class="text-xl">Directly from you when you provide it to us.</li>
      <li class="text-xl">Automatically when you use the Website. </li>
    </ul>

  <p><b>2.2</b> We do not collect special categories of personal data such as information relating to or about the below or information about criminal records.</p>
    <ul class="list-disc mt-4 ml-12">
      <li class="text-xl">Health </li>
      <li class="text-xl">Racial or ethnic origin </li>
       <li class="text-xl">Political opinions </li>
      <li class="text-xl">Membership of a political association</li>
       <li class="text-xl">Membership of a trade union </li>
      <li class="text-xl">Religious beliefs or affiliations</li>
       <li class="text-xl">Philosophical beliefs </li>
       <li class="text-xl">Sexual orientation or practices </li>
      <li class="text-xl">Biometric information  </li>
    </ul>



    <p><b>2.3</b> Information that you provide to us (“personal information”) when you register or join a group on our website may include:</p>
    <ul class="list-disc mt-4 ml-12">
      <li class="text-xl">First Name, Last Name; </li>
      <li class="text-xl">Email Address;
 </li>
       <li class="text-xl">Telephone number; and</li>
      <li class="text-xl">Banking information, credit card, and debit card number
</li>
      
    </ul>
    <p>This information may be collected as a result of you joining our website through Facebook  </p>
</ul>




 <p><b>2.4 </b> This information may also include:</p>
    <ul class="list-disc mt-4 ml-12">
      <li class="text-xl">Information that you provide by registering on the Website such as first name, last name, email address, telephone number and address (“Contact Information”). This includes information provided at the time of registering to use the Website and requesting further services.  </li>
      <li class="text-xl">Information you provide to us when you complete user surveys or surveys for research purposes such as your Contact Information.</li>
       <li class="text-xl">Information you provide to us when you report a problem with the Website such as your first name, last name and email address.
</li>
      <li class="text-xl">Records and copies of your correspondence (including e-mail addresses and phone numbers), if you contact us.
</li>
        <li class="text-xl">Details of transactions you carry out through the Website when you make payment to Salesforecast. 
</li>
      <li class="text-xl">Your correspondence within the Website with other users (your username and location). 
</li>
    </ul>


    <li class="text-2xl uppercase mt-8"><b>WHAT DOES SALESFORECAST USE INFORMATION COLLECTED FOR?</b></li>

    <p>Any information collected by us is for a purpose in relation to your use of our website, including for providing the services, processing, and delivering your orders. </p>


    <ul class="list-disc mt-4 ml-12">
      <li class="text-xl "><u><b><i>Provide Services to you:</i></b></u> We collect information to send you email about your registration, perform tasks required to complete a transaction, provide customer support, or provide other types of customer relationship management and fulfillment. We may also use your information to optimize or improve our Services and operations, for example, training and quality assurance.
 </li>
      <li class="text-xl mt-4"><u><b><i>Fulfilling legal and compliance obligations:</i></b></u> We collect information to fulfill our legal obligations. Examples of this may include satisfying regulatory screening requirements in connection with entity formations, responding to subpoenas and other legal requests for information, and maintaining records as required in our role as Registered Agent. We may also collect information to detect fraud or other suspicious activity. </li>
      <li class="text-xl mt-4"><u><b><i>Analytics:</i></b></u> We collect information for use in performing various types of analytics. For example, we use the information to analyze how visitors interact with our website, where visitors to our website arrive from and exit to, pages visited, links clicked, text entered and mouse movements to understand site usage, to detect fraud and potential threats and improve our services. </li>
      <li class="text-xl mt-4"><u><b><i>To communicate with you:</i></b></u> We collect your email address and phone number to (i) contact you regarding your use of our website/Website, (ii) respond to your questions, complaints, and queries, and (iii) send you updates regarding our services or this Policy and other agreements;
 </li>
      <li class="text-xl mt-4"><u><b><i>For other purposes through your consent:</i></b></u> We may also process your information for some other reasons which your consent will be sought before we process such information. You may also withdraw your consent at any time.
 </li>
    </ul>


    <li class="text-2xl uppercase mt-8"><b>HOW PERSONAL DATA IS COLLECTED.</b></li>
     <p><b>4.1 </b> Automatic Information Collection and Tracking.   </p>
     <p>When you access and use the Website, it may use technology to automatically collect:</p>
         <ul class="list-disc mt-4 ml-12">
            <li class="text-xl"><b><i>Usage Details.</i></b> When you access and use the Website, we may automatically collect certain details of your access to and use of the Website, including traffic data, location data, logs and other communication data and the resources that you access and use on or through the Website.</li>
         </ul>

      <p><b>4.2 </b> If you do not want us to collect this information, please do not create an account with us.   </p>
      <p><b>4.3 </b> We may also use these technologies to collect information about your activities over time and across third-party websites, Websites or other online services (behavioral tracking).   </p>
      <p><b>4.4 </b> Information Collection and Tracking Technologies.</p>
      <p>The technologies we use for automatic information collection may include:</p>

      <ul class="list-disc mt-4 ml-12">
      <li class="text-xl"><b><i>Cookies (or mobile cookies).</i></b> A cookie is a small file placed on your smartphone or device. It may be possible to refuse to accept mobile cookies by activating the setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website.
        <br><br>
      The cookies we use include “analytical” cookies. They allow us to recognize and count the number of visitors and to see how visitors move around the Website when they are using it. This helps us to improve the way our Website works, for example, by ensuring that users are finding what they are looking for easily.
        <br><br>
      These cookies are used to collect information about how visitors use our Website. We use the information to compile reports on general user traffic, conversion statistics and to help us improve the site. The cookies collect information anonymously.
        <br><br>
      Please note that our advertisers may also use cookies, over which we have no control.</li>

      <li class="text-xl mt-4"><b><u><i>Web Beacons.</i></u></b> Pages of the Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an e-mail and for other related Website statistics (for example, recording the popularity of certain Website content and verifying system and server integrity).</li>
      <li class="text-xl mt-4"><u><b><i>Third Party Cookies.</i></b></u>  The Website may link to other websites owned and operated by certain trusted third parties (for example Facebook) who, for example, collect data that helps us to track conversions from Facebook ads. These other third-party websites may also use cookies or similar technologies in accordance with their own separate polices. Please note that our advertisers may also use cookies, over which we have no control.  For privacy information relating to these other third-party websites, please consult their policies as Appropriate</li>
      

    </ul>
    <p><b>4.5</b> Third Party Software/Services</p>
      <p>We use the following third-party software/services to collect and process your personal data for the following reasons</p>
      <ul class="list-disc mt-4 ml-12">
      <li class="text-xl mt-4"><b><i>Stripe</i></b> – We use Stripe as a payment processing merchant to receive payment from our user for services provided through the Website </li>
      </ul>

      <li class="text-2xl uppercase mt-8"><b>HOW WE USE PERSONAL DATA AND LAWFUL BASES FOR PROCESSING</b></li>

      <p><b><u><i>5.1 Lawful Bases for Processing under the GDPR</i></u></b></p>
      <p>What are the lawful bases for processing personal data? The lawful bases for processing are set out in Article 6 of the GDPR.   At least one of these must apply whenever we process personal data. We mainly use consent, contract, legal obligations and legitimate interests as the bases to process your personal data in accordance with this privacy policy.</p>

      <ul class="list-disc mt-4 ml-12">
            <li class="text-xl"><b><i>Consent:</i></b> the individual has given clear consent for us to process their personal data for a specific purpose. </li>
            <li class="text-xl mt-4"><b><i>Contract:</i></b> the processing is necessary for a contract we have with the individual, or because they have asked us to take specific steps before entering into a contract. </li>
            <li class="text-xl mt-4"><b><i>Legal obligation:</i></b> the processing is necessary for us to comply with the law (not including contractual obligations). </li>
            <li class="text-xl mt-4"><b><i>Legitimate interests:</i></b> the processing is necessary for our legitimate interests or the legitimate interests of a third party unless there is a good reason to protect the individual’s personal data which overrides those legitimate interests.</li>
      </ul>

      <p><b>5.2</b> We use information that we collect about you or that you provide to us, including any personal information, to:</p>
      <ul class="list-disc mt-4 ml-12">
            <li class="text-xl">To set up and operate your Salesforecast account (such processing is necessary for the performance of our contract with you).</li>
            <li class="text-xl mt-4">To verify your identity and carry out security checks in order to set up your account (such processing is necessary for the performance of our contract with you and necessary for us to comply with a legal obligation).
</li>
            <li class="text-xl mt-4"> Provide you with the Website and its contents, and any other information, products or services that you request from us (such processing is necessary for the performance of our contract with you).</li>
            <li class="text-xl mt-4"> Give you notices about your account, the account you create (such processing is necessary for the performance of our contract with you and necessary for us to comply with a legal obligation).</li>
            <li class="text-xl mt-4"> Ensure that content from our Website is presented in the most effective manner for you and for your computer or device for accessing the Website (such processing is necessary for the performance of our contract with you).</li>
            <li class="text-xl mt-4">Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection (such processing is necessary for our legitimate interests).
</li>
            <li class="text-xl mt-4"> To manage your account, including processing payments and providing notifications, should this service be introduced within the Website (such processing is necessary for the performance of our contract with you and is necessary for our legitimate interests (to process payments).
</li>
      </ul>

      <p><b>5.3</b> The usage information we collect helps us to improve our Website and to deliver a better and more personalized experience by enabling us to:</p>
      <ul class="list-disc mt-4 ml-12">
            <li class="text-xl mt-4">Estimate our audience size and usage patterns (such processing is necessary for our legitimate interests (for running our services and to study how users use our Website).</li>
            <li class="text-xl mt-4">Store information about your preferences, allowing us to customize our Website according to your individual interests (such processing is necessary for our legitimate interests (for running our services).</li>
            <li class="text-xl">Speed up your searches (such processing is necessary for our legitimate interests (for running our services).</li>
            <li class="text-xl mt-4">Recognize you when you use the Website (such processing is necessary for the performance of our contract).</li>
            <li class="text-xl mt-4">To provide us with information about how the Website is running or whether there are any faults or issues with our products and services (such processing is necessary for our legitimate interests (for us to deliver a better service to you).</li>
      </ul>

      <p><b>5.4</b> We may also use your information to contact you about news, offers, notifications, surveys, information, goods and services that we think may be of interest to you (such processing is done with your consent and is necessary for our legitimate interests (to develop our products/services and grow our business)). We give you the option to consent to such emails when you sign up for the Website. If you do not want us to use your information in this way, please do not consent.  You may adjust your user preferences in your account profile.</p>
      <p><b>5.5</b> We have implemented reasonable measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration and disclosure.</p>
      <p><b>5.6</b> The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Website like message boards. The information you share in public areas may be viewed by any user of the Website.</p>
      <p><b>5.7</b> Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide. </p>
      <p><b>5.8</b> If you suspect that there has been a breach of the security of your data you should contact us at: support@salesforecast.io and include details of: </p>
      <ul class="list-disc mt-4 ml-12">
            <li class="text-xl mt-4"> the nature of the breach; </li>
            <li class="text-xl mt-4"> the date of the breach; and</li>
            <li class="text-xl mt-4"> the full circumstances of the breach. </li>
            <li class="text-xl mt-4"> On notification of such a breach we will investigate the matter and, where appropriate and required by law, notify the relevant Data Protection Regulator.</li>
      </ul>

      <li class="text-2xl uppercase mt-8"><b>USING THE APP OR VISITING OUR WEBSITES FROM OUTSIDE THE UNITED STATES</b></li>
      <p>This Privacy Policy is intended to cover collection of information on or via the App or our website from residents of the United States. If you are using the App or visiting our website from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located and our central database is operated. The data protection and other laws of the United States and other countries might not be as comprehensive as those in your country. Please be assured that we seek to take reasonable steps to ensure that your privacy is protected. By using our services, you understand that your information may be transferred to our facilities and those third parties with whom we share it as described in this privacy policy.</p>

      <li class="text-2xl uppercase mt-8"><b>HOW PERSONAL DATA IS SHARED AND WITH WHOM</b></li>
      <p>Salesforecast does not sell or share your personal information to third parties but may share your information if need arises such as the following: </p>
      <ul class="list-disc mt-4 ml-12">
            <li class="text-xl mt-4"> To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of the Company’s assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by the Company about our Website users is among the assets transferred. </li>
            <li class="text-xl mt-4"> For any other purpose disclosed by us when you provide the information.
</li>
            <li class="text-xl mt-4">  For the purposes of academic research. </li>
            <li class="text-xl mt-4">  For any other purpose with your consent. </li>
             <li class="text-xl mt-4">  To comply with any court order, law or legal process, including to respond to any government or regulatory request.
</li>
            <li class="text-xl mt-4"> To enforce our rights arising from any contracts entered into between you and us. </li>
      </ul>

      <p>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</p>
      <li class="text-2xl uppercase mt-8"><b> WHAT ARE YOUR PRIVACY RIGHTS?</b></li>
      <p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a class="text-blue-500" href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a></p>

      <p>If you have questions or comments about your privacy rights, you may email us at support@salesforecast.io</p>

      <p><b><i><u>Account Information</u></i></b></p>

      <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>

      <p style="display: inline-flex" class="ml-4"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>  <span class="ml-4 ">Log into your account settings and update your user account.</span></p>

      <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>

      <p><b><i><u>Opting out of email marketing:</u></i></b> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account</p>

      <li class="text-2xl uppercase mt-8"><b> YOUR RIGHTS WITH RESPECT TO PERSONAL DATA.</b></li>

      <ul class="list-disc mt-4 ml-12">
            <li class="text-xl mt-4">  You can review and change your personal information by logging into the Website and visiting your account settings page. </li>
            <li class="text-xl mt-4">  You may also send us an e-mail at support@salesforecast.io to request access to, correct or delete any personal information free of charge that you have provided to us. We cannot delete your personal information except by also deleting your user account. 

</li>
            <li class="text-xl mt-4">  We will respond to a request to access, correct or delete any data within 30 days of the date we receive the request.  </li>
            <li class="text-xl mt-4">  We may not accommodate a request to change information if we believe the change would violate or breach any law or legal requirement or cause the information to be incorrect. </li>
             <li class="text-xl mt-4">   If, on your request, we refuse to amend, correct or delete your personal information, we will set out our reasons for not doing so and provide you with details of how you may complain about our refusal.

</li>
            <li class="text-xl mt-4">  If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other Website users. Proper access and use of information provided on the Website, including User Contributions, is governed by our Terms and Conditions.
 </li>
      </ul>

      <li class="text-2xl uppercase mt-8"><b>CHILDREN POLICY</b></li>

      <p>We do not permit users that are below the age of majority (usually 18 years in most countries) to submit personal information and purchase from us unless they have support from their parent or legal guardian. By submitting personal information to us, you represent to us that you are 18 years of age or above and that you are able to be bind by this Policy.</p>


      <li class="text-2xl uppercase mt-8"><b> HOW LONG PERSONAL DATA IS KEPT
</b></li>

          <ul class="list-disc mt-4 ml-12">            
            <li class="text-xl mt-4">  We will only retain personal data for as long as is reasonably necessary.</li>
            <li class="text-xl mt-4">  Upon deletion of an account, all personal data will be removed as soon as possible, and always within 90 days of the deletion.
</li>
      </ul>


      <li class="text-2xl uppercase mt-8"><b> LEGAL BASIS FOR THE PROCESSING OF PERSONAL INFORMATION</b></li>
<ul class="list-disc mt-4 ml-12">            
            <li class="text-xl mt-4">  We rely upon both consent and legitimate business interests for processing personal information. For example, we may process personal information with a User’s consent when they agree that we may place cookies on their devices, or that we can process information that they enter into a form on one of our sites.</li>

            <li class="text-xl mt-4">  We will only retain personal data for as long as is reasonably necessary.</li>
            <li class="text-xl mt-4"> It is in our interest as a business to enhance the services that we provide which may include processing your information to enable us to: </li>
            <li class="text-xl mt-4">  We will only retain personal data for as long as is reasonably necessary.</li>
<li class="text-xl mt-4"> Respond to User enquiries and to fulfil User requests</li>
<li class="text-xl mt-4"> Send Customers or Potential Customers relevant marketing information and offers</li>
<li class="text-xl mt-4">Complete transactions</li>
<li class="text-xl mt-4"> Provide customer service</li>
<li class="text-xl mt-4"> Send administrative information</li>
<li class="text-xl mt-4"> Personalize Users’ experiences with our sites</li>
<li class="text-xl mt-4">Do so where we are required to by law</li>

<li class="text-xl mt-4">We may also process personal information when it is in our or a Users’ legitimate interests to do so and when these interests are not overridden by an individual’s data protection rights. </li>
</ul>

<li class="text-2xl uppercase mt-8"><b>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b></li>
<p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
<p>If you are under 18 years of age, reside in California, and have a registered account with the Services or Websites, you have the right to request removal of unwanted data that you publicly post on the Services or Websites. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services or Websites, but please be aware that the data may not be completely or comprehensively removed from our systems.</p>
<li class="text-2xl uppercase mt-8"><b>DATA SECURITY</b></li>
<p>We have implemented multiple security measures designed to secure your personal information from accidental loss and unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology.</p>
<p>The safety and security of your information also depend on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
<p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</p>
<li class="text-2xl uppercase mt-8"><b>CHANGES TO OUR PRIVACY POLICY</b></li>
<p>It is our policy to post any changes we make to our privacy policy on this page with a notice that the privacy policy has been updated on the Website home page. If we make material changes to how we treat our users' personal information, we will notify you by e-mail to the primary e-mail address specified in your account and/or through a notice on the Website home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable e-mail address for you, and for periodically visiting our Website and this privacy policy to check for any changes.</p>





</section>
    </div>
</template>
<script>
export default {
    
}
</script>