<template>
    <div>
            <section id="" class="bg-white border-b py-8 hero">
    
    <div class="container mx-auto flex flex-wrap pt-4 pb-12">
    

    
        <div class="w-full md:w-1/5 p-6 flex flex-col flex-grow flex-shrink">
        </div>
        
        
        
        <div class="w-full md:w-3/5 p-6 flex flex-col flex-grow flex-shrink">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/458005738?autoplay=1" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
        </div>
        
        
        
        <div class="w-full md:w-1/5 p-6 flex flex-col flex-grow flex-shrink">
            
        </div>
        
        
    </div>

</section>

      <section class="bg-blue-500 mx-auto text-center py-6 mb-12">

    <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-dark">Call to Action</h1>
    <div class="w-full mb-4">   
        <div class="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
    </div>

    <h3 class="my-4 text-3xl leading-tight">Main Hero Message to sell yourself!</h3>    

    <button class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg">Action!</button>
        
</section>
        
        
<section class="container mx-auto px-2 pt-4 pb-12 text-gray-800">
    <div class="py-12 bg-white">
  <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-40">
    <div class="lg:text-center">
      <h3 class="mt-2 pb-12 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Frequently Asked Questions
      </h3>
    
    </div>

    <div class="mt-10">
      <ul class="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
        <li>
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center rounded-md  text-gray-700">
                <!-- Heroicon name: globe-alt -->
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path></svg>
              </div>
            </div>
            <div class="ml-4">
              <h4 class="text-3xl leading-6 font-medium text-gray-700 leading-8">Can I cancel my Subscripton?</h4>
              <p class="mt-6 text-xl leading-6 text-gray-500 leading-8">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
              </p>
            </div>
          </div>
        </li>
        <li class="mt-10 md:mt-0">
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center  rounded-md  text-gray-700">
                <!-- Heroicon name: scale -->
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"></path></svg>
              </div>
            </div>
            <div class="ml-4">
              <h4 class="text-3xl leading-6 font-medium text-gray-700 leading-8">Is there any discount for an annual subscription?</h4>
              <p class="mt-6 text-xl leading-6 text-gray-500 leading-8">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
              </p>
            </div>
          </div>
        </li>
        <li class="mt-10 md:mt-0">
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center  rounded-md  text-gray-700">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
              </div>
            </div>
            <div class="ml-4">
              <h4 class="text-3xl leading-6 font-medium text-gray-700 leading-8">Which Payment method do you take?</h4>
              <p class="mt-6 text-xl leading-6 text-gray-500 leading-8">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
              </p>
            </div>
          </div>
        </li>
        <li class="mt-10 md:mt-0">
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center  rounded-md 0 text-gray-700">
                <!-- Heroicon name: annotation -->
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
              </div>
            </div>
            <div class="ml-4">
              <h4 class="text-3xl leading-6 font-medium text-gray-700 leading-8">Any other questions we can answer?</h4>
              <p class="mt-6 text-xl leading-6 text-gray-500 leading-8">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

</section>      


<section class="bg-gray-100 py-8">


    
    <div class="container mx-auto px-2 pt-4 pb-12 text-gray-800 lg:text-center">
        <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
       To get started you need to chosse a plan for your needs. You have free unlimited updates and Premium Support on each package.
      </p>
        
        <div class="w-full mb-4">   
            <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
    
    
        
        <div class="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
            
    <div class="w-full p-4 md:w-1/2 lg:w-1/4">          
      <label class="flex flex-col rounded-lg shadow-lg relative cursor-pointer hover:shadow-2xl">
            <div class="w-full px-4 py-8 rounded-t-lg bg-gray-800">
              <h3 class="mx-auto text-base font-semibold text-center  text-white group-hover:text-white">
                Professional
              </h3>
              <p class="text-4xl  text-center text-white">
                $40
              </p>
              <p class="text-xs text-center uppercase text-white">
                monthly
              </p>
            </div>
            <div class="flex flex-col items-center justify-center w-full h-full py-6 border-b-2 bg-gray-700">
              <p class="text-lg  text-gray-300 ">
                Unlimited MB
              </p>
             </div>
             <div class="flex flex-col items-center justify-center w-full h-full py-6 border-b-2  bg-gray-700">
              <p class="text-lg  text-gray-300 ">
                Unlimited Emails
              </p>
          </div>
             <div class="flex flex-col items-center justify-center w-full h-full py-6 border-b-2 ed-b-lg bg-gray-700">
              <p class="text-lg  text-gray-300 ">
                Full Support
              </p>
          </div>
          <div class="flex flex-col items-center justify-center w-full h-full py-6 border-b-2 ed-b-lg bg-gray-800">
              <button class="w-5/6 py-2 mt-2 font-semibold text-center uppercase bg-blue-500 border border-transparent rounded text-white">
                Current Plan
              </button>
            </div>
          </label>
        </div>
            
            
           

        </div>
        
    </div>
        
        
</section>
    </div>
</template>
<script>
export default {
    
}
</script>