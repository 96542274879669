import Home from './components/Home.vue';
import Termsconditions from './components/Termsconditions.vue';
import Privacypolicy from './components/Privacypolicy.vue';

const routes = [
    { path: '/', component: Home },
    { path: '/terms-conditions', component: Termsconditions },
    { path: '/privacy-policy', component: Privacypolicy },
];

export default routes;